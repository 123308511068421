import i18n from "@config/i18n";
import { useWindowSize } from "@hooks/useWindowSize";
import { cn } from "@lib/utils";
import { Button } from "@ui/button";
import { Calendar } from "@ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@ui/popover";
import { format } from "date-fns";
import { de, enGB } from "date-fns/locale";
import { t } from "i18next";
import { Calendar as CalendarIcon } from "lucide-react";
import * as React from "react";
import { useEffect, useState } from "react";
import { Matcher } from "react-day-picker";

export function SingleDatePicker({
  className,
  onValueChange,
  dateProps,
  onSave,
  isDayHighlighted,
  disabled,
  numberOfMonths,
  defaultMonth,
}: React.HTMLAttributes<HTMLDivElement> & {
  onValueChange?: (
    date?: Date,
  ) => { warning?: boolean; warningText?: string } | undefined;
  onSave?: (date: Date) => void;
  dateProps?: Date | undefined;
  isDayHighlighted?: (date: Date) => boolean;
  disabled?: Matcher | Matcher[] | undefined;
  numberOfMonths?: number;
  defaultMonth?: Date;
}) {
  const { width } = useWindowSize();
  const [open, setOpen] = useState(false);
  const [messages, setMessages] = useState<
    { warning?: boolean; warningText?: string } | undefined
  >();
  const [dateState, setDateState] = useState<Date | undefined>(dateProps);
  useEffect(() => {
    if (!onValueChange) {
      return;
    }

    const response = onValueChange(dateState);
    setMessages(response);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateState]);

  return (
    <div className={cn("grid gap-2", className)}>
      <Popover open={open} onOpenChange={(e) => setOpen(e)}>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            size="sm"
            className={cn(
              "justify-start text-left font-normal font-body h-10",
              !dateState && "text-muted-foreground",
            )}
          >
            <CalendarIcon className="mr-2 h-4 w-4" />
            {dateState ? (
              format(dateState, "LLL dd, y", {
                locale: i18n.language === "de" ? de : enGB,
              })
            ) : (
              <span className="">{t("global_choose-date")}</span>
            )}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="end">
          <div className="flex md:flex-row flex-col">
            <Calendar
              initialFocus
              mode="single"
              defaultMonth={defaultMonth || dateState}
              selected={dateState}
              onDayClick={setDateState}
              isDayHighlighted={isDayHighlighted}
              disabled={disabled}
              numberOfMonths={numberOfMonths ? 1 : width && width >= 768 ? 2 : 1}
            />
          </div>
          <div className="p-3 flex justify-between items-center sm:flex-row gap-2 flex-col">
            <Button variant={"outline"} onClick={() => setOpen(false)}>
              {t("global_cancel-btn")}
            </Button>
            {messages?.warning && messages.warningText ? (
              <div className="text-xs">{t(messages.warningText)}</div>
            ) : null}
            {onSave ? (
              <Button
                onClick={() => {
                  if (!dateState) return;
                  onSave(dateState);
                  setOpen(false);
                }}
              >
                {t("global_save-btn")}
              </Button>
            ) : null}
          </div>
        </PopoverContent>
      </Popover>
    </div>
  );
}
