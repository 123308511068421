import * as CookieAccess from "@access/cookie-storage";
import { AUTH_API_ROUTES, CUSTOMER_API_ROUTES } from "@config/api";
import { storageKeys } from "@config/common";
import { fetchWithToken, getToken, patch, postForm, postWithToken } from "@lib/request";
import {
  OATH_RESPONSE_FAILED,
  OATH_RESPONSE_OK,
  OAUTH_SIGN_UP_RESPONSE,
} from "@models/auth";
import {
  BusinessTypeEnum,
  DepartmentEnum,
  IndustryEnum,
  User as UserModel,
} from "@models/user";
import { JWT_INTERFACE } from "@typesClient/auth";
import jwt_decode from "jwt-decode";

import { AnnualPurchasingVolumeEnum } from "./../models/user";

export async function getUser(): Promise<{
  data: UserModel | null;
  error?: string;
}> {
  let response: UserModel | null = null;
  let errorResponse: string | undefined;
  try {
    const { data: result } = await fetchWithToken<{ data: UserModel; status: number }>(
      AUTH_API_ROUTES.USER_INFO,
    );
    if (result?.data) {
      response = result?.data;
      CookieAccess.setCookie(storageKeys.contactUUID, response.contactUUID);
    }
  } catch (error: any) {
    errorResponse = error.message;
  }
  return {
    data: response,
    error: errorResponse,
  };
}

export async function login(option: { email: string; password: string }): Promise<{
  error: OATH_RESPONSE_FAILED | null;
  data: OATH_RESPONSE_OK | null;
}> {
  const data = {
    grant_type: import.meta.env.VITE_API_GRANT_TYPE,
    client_id: import.meta.env.VITE_API_CLIENT_ID,
    realm: import.meta.env.VITE_API_REALM,
    audience: import.meta.env.VITE_API_AUDIENCE,
    username: option.email,
    password: option.password,
  };
  let result: OATH_RESPONSE_OK | null = null;
  let errorResponse: OATH_RESPONSE_FAILED | null = null;
  try {
    result = await postForm<OATH_RESPONSE_OK>(AUTH_API_ROUTES.LOGIN, data);
  } catch (error: any) {
    errorResponse = {
      error: error?.response?.data?.error ? error.response.data.error : error.code,
      error_description: error?.response?.data?.error_description
        ? error.response.data.error_description
        : error.message,
    };
  }
  return {
    error: errorResponse,
    data: result,
  };
}

export async function sign_up_auth0(option: {
  email: string;
  password: string;
  server_token?: string;
}): Promise<{
  error: OATH_RESPONSE_FAILED | null;
  data: OAUTH_SIGN_UP_RESPONSE | null;
}> {
  const data = {
    grant_type: import.meta.env.VITE_API_GRANT_TYPE,
    client_id: import.meta.env.VITE_API_CLIENT_ID,
    connection: import.meta.env.VITE_API_REALM,
    ...option,
  };
  let result: OAUTH_SIGN_UP_RESPONSE | null = null;
  let errorResponse: OATH_RESPONSE_FAILED | null = null;
  try {
    result = await postForm<OAUTH_SIGN_UP_RESPONSE>(AUTH_API_ROUTES.SIGN_UP, data);
  } catch (error: any) {
    errorResponse = {
      error: error?.response?.data?.code ? error.response.data.code : error.code,
      error_description: error?.response?.data?.description
        ? error.response.data.description
        : error.message,
    };
  }
  return {
    error: errorResponse,
    data: result,
  };
}
export interface SignupPayload {
  contactData: {
    companyName?: string | null;
    role?: keyof typeof DepartmentEnum | null;
    firstName?: string | null;
    lastName?: string | null;
    postalCode?: string | null;
    salutation?: "Herr" | "Frau" | null;
    email: string;
    city?: string | null;
    street?: string | null;
    country?: string | null;
    phone?: string | null;
    lang: string;
    acceptedTerms: boolean;
    businessType?: keyof typeof BusinessTypeEnum | null;
    industry?: keyof typeof IndustryEnum | null;
    annualPurchasingVolume: keyof typeof AnnualPurchasingVolumeEnum | null;
  };
  contactId?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
  utmAdSet?: string;
  utmContent?: string;
  utmTerm?: string;
  gclid?: string;
  lastScreen: 0;
  formVersion: "Dashboard_SignUp";
  crefonummer?: string | null;
  identnummer?: string | null;
}
export async function sign_up(
  payload: SignupPayload,
  token: string,
  urlParams: any,
): Promise<{
  error: OATH_RESPONSE_FAILED | null;
  data: OAUTH_SIGN_UP_RESPONSE | null;
}> {
  let result: OAUTH_SIGN_UP_RESPONSE | null = null;
  let errorResponse: OATH_RESPONSE_FAILED | null = null;
  try {
    result = (await postWithToken(CUSTOMER_API_ROUTES.SIGN_UP, token, {
      ...payload,
      ...urlParams,
    })) as unknown as OAUTH_SIGN_UP_RESPONSE;
  } catch (error: any) {
    errorResponse = {
      error: error?.response?.data?.code ? error.response.data.code : error.code,
      error_description: error?.response?.data?.description
        ? error.response.data.description
        : error.message,
    };
  }
  return {
    error: errorResponse,
    data: result,
  };
}

export async function resetPassword({
  email,
}: {
  email: string;
}): Promise<{ data: string | undefined; status: number }> {
  try {
    return await patch<{ data: string | undefined; status: number }>(
      AUTH_API_ROUTES.RESET_PASSWORD,
      { email },
    );
  } catch (error: any) {
    return { data: undefined, status: error.response.status };
  }
}

export async function logout(): Promise<void> {
  CookieAccess.removeCookie(storageKeys.token);
  CookieAccess.removeCookie(storageKeys.userId);
  CookieAccess.removeCookie(storageKeys.contactUUID);
}

export async function sessionCheck(): Promise<{
  sessionAvailablity: boolean;
  token: string | undefined;
}> {
  const { token } = await getToken();
  if (!token) {
    return { sessionAvailablity: true, token: undefined };
  }
  const decodedToken = jwt_decode(token) as JWT_INTERFACE;
  // Check cookie with a validity period of 7 days.
  if (decodedToken.exp * 1000 < new Date().getTime()) {
    return { sessionAvailablity: true, token: undefined };
  }
  return { sessionAvailablity: false, token };
}
