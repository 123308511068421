import { COUNTRIES } from "@assets/countries";
import { Icons } from "@components/icons";
import PartFramework from "@components/inquiry/components/part-framework";
import PartSingle from "@components/inquiry/components/part-single";
import InquiryLoader from "@components/inquiry/inquiry-loader";
import Metadata from "@components/metadata";
// import FeedbackForm from "@components/new-offer/feedback.form";
import OrderConditions from "@components/new-order/components/order-conditions";
import { PAGES_ROUTES } from "@config/routes";
import { useGetOfferDetailsId } from "@hooks/useGetOfferDetailsId";
import { useGetTaxByCountry } from "@hooks/useGetTaxByCountry";
import useObjectTranslations from "@hooks/useObjectTranslations";
import { useScrollToTop } from "@hooks/useScrollToTop";
import { MoneyFactory, MoneyUtils } from "@lib/money";
import { cn } from "@lib/utils";
import { OfferStatus } from "@models/offer";
// import { useDialogStore } from "@store/dialog.store";
import { useUserStore } from "@store/user.store";
import { useInquiry } from "@store/wizard-inqury";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@ui/accordion";
import { Badge } from "@ui/badge";
import { Button } from "@ui/button";
import { Card, CardContent, CardHeader } from "@ui/card";
import { Separator } from "@ui/separator";
import { Tabs, TabsList, TabsTrigger } from "@ui/tabs";
import { getColorForOfferStatus } from "@utils/badge";
import { trackStartOrderProccess } from "@utils/tracking";
import { format, parseISO } from "date-fns";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useNavigate, useParams } from "react-router-dom";

// eslint-disable-next-line react-hooks/rules-of-hooks
const offerStatusTranslationObject = useObjectTranslations("offersStatus");
// eslint-disable-next-line react-hooks/rules-of-hooks
const typeOfOrderTranslationObject = useObjectTranslations("typeOfOrder");
const InqueryIdNew = () => {
  useScrollToTop();
  const { t } = useTranslation();
  const [showButton, setShowButton] = useState(false);
  useEffect(() => {
    // Button is displayed after scrolling for 300 pixels
    const handleScrollButtonVisiblity = () => {
      window.pageYOffset > 300 ? setShowButton(true) : setShowButton(false);
    };
    window.addEventListener("scroll", handleScrollButtonVisiblity);
    return () => {
      window.removeEventListener("scroll", handleScrollButtonVisiblity);
    };
  }, []);
  const [accordionClose, setAccordionClose] = useState(false);
  const [filterTable, setFilterTable] = useState<"all" | "framework" | "single">("all");
  const params = useParams();
  // const { show } = useDialogStore((state) => state);
  const navigate = useNavigate();
  const { singleOfferLines, frameworkOfferLines, setOrderDetails, reset } = useInquiry();
  useEffect(() => {
    reset();
  }, []);
  const { data: offerDetails, isPending } = useGetOfferDetailsId(params.offerId);
  const { data: taxResponse } = useGetTaxByCountry({
    country: offerDetails?.data?.shippingCountry as string,
  });
  const { user } = useUserStore();
  if (isPending) return <InquiryLoader />;
  const handleCreateInquiry = () => {
    trackStartOrderProccess({ userId: user?.contactId as string });
    setOrderDetails("totalPrice", totalPrice);

    navigate(PAGES_ROUTES.NEW_INQURY_ID_URL(params.offerId as string));
  };
  const faTotalPrice = frameworkOfferLines
    .map((item) => {
      const selectedItem = item.quantities.find(
        (q) => item.selectedOfferLineId === q.offerLineId,
      );
      const totalPriceNetOfCertificates = item?.certificateLines?.reduce(
        (acc, certificateLine) => {
          return acc + (certificateLine.totalPriceNet || 0);
        },
        0,
      );

      return (selectedItem?.totalPriceNet || 0) + (totalPriceNetOfCertificates || 0);
    })
    .reduce((acc, item) => acc + item, 0);
  const faTotalPriceBeforeDiscount = frameworkOfferLines
    .map((item) => {
      const selectedItem = item.quantities.find(
        (q) => item.selectedOfferLineId === q.offerLineId,
      );
      const totalPriceNetOfCertificates = item?.certificateLines?.reduce(
        (acc, certificateLine) => {
          return acc + (certificateLine.totalPriceNetBeforeDiscount || 0);
        },
        0,
      );

      return (
        (selectedItem?.totalPriceNetBeforeDiscount || 0) + totalPriceNetOfCertificates
      );
    })
    .reduce((acc, item) => acc + item, 0);
  const soTotalPrice = singleOfferLines
    .map((item) => {
      const selectedItem = item.quantities.find(
        (q) => item.selectedOfferLineId === q.offerLineId,
      );
      const totalPriceNetOfCertificates = item?.certificateLines?.reduce(
        (acc, certificateLine) => {
          return acc + (certificateLine.priceNet || 0);
        },
        0,
      );

      return (selectedItem?.totalPriceNet || 0) + (totalPriceNetOfCertificates || 0);
    })
    .reduce((acc, item) => acc + item, 0);

  const soTotalPriceBeforeDiscount = singleOfferLines
    .map((item) => {
      const selectedItem = item.quantities.find(
        (q) => item.selectedOfferLineId === q.offerLineId,
      );
      const totalPriceNetOfCertificates = item?.certificateLines?.reduce(
        (acc, certificateLine) => {
          return acc + (certificateLine.totalPriceNetBeforeDiscount || 0);
        },
        0,
      );

      return (
        (selectedItem?.totalPriceNetBeforeDiscount || 0) +
        (totalPriceNetOfCertificates || 0)
      );
    })
    .reduce((acc, item) => acc + item, 0);
  const totalPriceBeforeDiscount =
    (faTotalPriceBeforeDiscount || 0) + (soTotalPriceBeforeDiscount || 0);
  const totalPrice = (faTotalPrice || 0) + (soTotalPrice || 0);

  const taxPrice = taxResponse?.data
    ? (taxResponse?.data?.taxPercent / 100) *
      ((totalPrice === totalPriceBeforeDiscount
        ? totalPrice
        : totalPriceBeforeDiscount) || 0)
    : 0;
  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="mb-12 relative">
      {showButton && (
        <div>
          <button
            className="fixed bottom-5 right-7 z-50 cursor-pointer p-4"
            onClick={handleScrollToTop}
          >
            <Icons.arrowRightIcon className="size-8 -rotate-90" />
          </button>
        </div>
      )}
      <Metadata>
        <title>
          {t("order-details-sidebar_offer")} {offerDetails?.data?.offerNr || ""} | CNC24
        </title>
      </Metadata>
      <div className="mb-12 flex justify-between items-center sticky top-0 z-30">
        <>
          <Link to={PAGES_ROUTES.OFFERS}>
            <Button
              className="flex items-center justify-between font-display pl-0"
              variant={"ghost"}
            >
              <Icons.chevronLeft /> <span>{t("global_go-back-btn")}</span>
            </Button>
          </Link>
          <div className="flex justify-between space-x-4">
            {user &&
              user?.email_verified &&
              offerDetails?.data?.displayAcceptOfferProcessButton && (
                <Button
                  onClick={handleCreateInquiry}
                  // disable if there is  no framework offer lines or single offer lines
                  disabled={frameworkOfferLines.length < 1 && singleOfferLines.length < 1}
                >
                  {frameworkOfferLines.length < 1 && singleOfferLines.length < 1
                    ? t("dashboard-please_select_item")
                    : t("procceed_to_checkout")}
                </Button>
              )}
          </div>
        </>
      </div>
      <div className="space-y-8">
        <div className="grid lg:grid-cols-3 sm:gap-8 gap-4 grid-cols-2">
          <Card className="lg:col-span-1 col-span-2 flex items-center border-none shadow-2xl">
            <CardContent className="sm:p-8 p-4 space-y-4">
              <div className="flex space-x-3">
                <Badge
                  variant={getColorForOfferStatus(
                    offerDetails?.data?.status as OfferStatus,
                  )}
                >
                  {offerStatusTranslationObject[offerDetails?.data?.status as string]}
                </Badge>
                <Badge variant={"secondary"}>
                  {
                    typeOfOrderTranslationObject[
                      offerDetails?.data?.typeOfOrder as string
                    ]
                  }
                </Badge>
              </div>
              <div className="font-bold font-display">
                <h2 className="sm:text-3xl text-2xl">{offerDetails?.data?.offerNr}</h2>
                <p className="text-sm text-muted-foreground">
                  {offerDetails?.data?.reference || "-"}
                </p>
              </div>
              {offerDetails?.data?.createdDate && (
                <div className="text-sm space-x-1">
                  <span>{t("offers-table-columns_created-at")}: </span>
                  <span className="font-bold font-display">
                    {offerDetails?.data?.createdDate
                      ? format(parseISO(offerDetails?.data?.createdDate), "dd.MM.yyyy")
                      : "-"}
                  </span>
                </div>
              )}
            </CardContent>
          </Card>
          <Card className="col-span-2 font-bold font-display border-none shadow-2xl">
            <CardContent className="grid md:grid-cols-2 grid-cols-1 sm:gap-4 gap-2 sm:p-8 p-4">
              <Card className="flex items-center min-h-40">
                <CardContent className="sm:p-6 p-3 flex flex-col justify-center w-full">
                  <div className="text-sm">{t("offers-table-columns_components")}</div>
                  <h3 className="text-2xl font-display">
                    {offerDetails?.data?.numberOfParts || "-"}
                  </h3>
                </CardContent>
              </Card>
              <Card className="flex items-center min-h-40">
                <CardContent className="sm:p-6 p-3 flex flex-col justify-center w-full">
                  <div className="text-sm">{t("dashboard-price")}</div>
                  {totalPrice ? (
                    <div>
                      {totalPriceBeforeDiscount === totalPrice ? (
                        <div className="text-2xl font-bold font-display">
                          {MoneyUtils.format(
                            MoneyFactory.create(
                              totalPrice,
                              offerDetails?.data?.currencyISOCode,
                            ),
                          )}
                        </div>
                      ) : (
                        <div className="w-full">
                          <h1 className="text-2xl font-display text-brand">
                            {MoneyUtils.format(
                              MoneyFactory.create(
                                totalPrice,
                                offerDetails?.data?.currencyISOCode,
                              ),
                            )}
                          </h1>
                          <div className="line-through text-sm">
                            {MoneyUtils.format(
                              MoneyFactory.create(
                                totalPriceBeforeDiscount,
                                offerDetails?.data?.currencyISOCode,
                              ),
                            )}
                          </div>
                        </div>
                      )}
                      <Accordion
                        onValueChange={(value) => setAccordionClose(Boolean(value))}
                        type="single"
                        collapsible
                        className="w-full"
                      >
                        <AccordionItem value="item-1">
                          <AccordionTrigger className="font-bold font-display text-muted-foreground text-sm flex justify-between w-full">
                            <span className="text-left">
                              {accordionClose
                                ? t("dashboard-hidepricebreakdown")
                                : t("dashboard-viewpricebreakdown")}
                            </span>
                          </AccordionTrigger>
                          <AccordionContent>
                            <Separator className="mb-3" />
                            <ul className="grid gap-3 text-xs">
                              <li className="flex justify-between">
                                <span>
                                  {t("summary-table_tax")}{" "}
                                  {`(${taxResponse?.data?.taxPercent || 0}%)`}
                                </span>
                                {MoneyUtils.format(
                                  MoneyFactory.create(
                                    taxPrice,
                                    offerDetails?.data?.currencyISOCode,
                                  ),
                                )}
                              </li>
                              <li className="flex justify-between">
                                <span>{t("offers-table-columns_price")}</span>

                                {MoneyUtils.format(
                                  MoneyFactory.create(
                                    totalPrice + taxPrice,
                                    offerDetails?.data?.currencyISOCode,
                                  ),
                                )}
                              </li>
                            </ul>
                            <Separator className="mt-3" />
                          </AccordionContent>
                        </AccordionItem>
                      </Accordion>
                    </div>
                  ) : (
                    <span className="text-sm italic font-normal font-body">
                      {t("dashboard-total_price_not_selected")}
                    </span>
                  )}
                </CardContent>
              </Card>
            </CardContent>
          </Card>
        </div>
        {offerDetails?.data?.offerComments ? (
          <div className="p-6 border rounded-lg flex items-center space-x-4 bg-[#FFF2E8] mb-2">
            <Icons.warningCircle className="text-[#FD7E14]" />
            <span className="text-sm space-x-1">
              <span
                dangerouslySetInnerHTML={{ __html: offerDetails?.data?.offerComments }}
              />
            </span>
          </div>
        ) : null}
        <Card className="border-none shadow-2xl">
          <CardHeader className="flex flex-row items-center justify-between sm:pb-0">
            <div className="flex justify-between space-x-4 w-full">
              <h1 className="text-2xl font-bold font-display">
                {t("offers-table-columns_components")}
              </h1>
              {/* <Button
                variant="outlineprimary"
                onClick={() => {
                  show({
                    content: <FeedbackForm />,
                    side: "right",
                  });
                }}
              >
                {t("dashboard-give_feedback")}
              </Button> */}
            </div>
            <div>
              <Tabs
                value={filterTable}
                onValueChange={(val) =>
                  setFilterTable(val as "all" | "framework" | "single")
                }
                defaultValue="all"
                className={cn(
                  "space-y-4",
                  ((offerDetails?.data?.singleOfferLines &&
                    offerDetails?.data?.singleOfferLines.length < 1) ||
                    (offerDetails?.data &&
                      offerDetails?.data?.frameworkOfferLines.length < 1)) &&
                    "hidden",
                )}
              >
                <TabsList className="backdrop-blur-md bg-white/30">
                  {[
                    { text: "status-all", value: "all" },
                    {
                      text: "order-details-sidebar_framework-agreement",
                      value: "framework",
                    },
                    {
                      text: "dashboard-singleorder",
                      value: "single",
                    },
                  ].map(({ text, value }) => (
                    <TabsTrigger key={value} value={value}>
                      {t(text)}
                    </TabsTrigger>
                  ))}
                </TabsList>
              </Tabs>
            </div>
          </CardHeader>
          <CardContent className="p-6 space-y-10">
            {offerDetails?.data?.frameworkOfferLines &&
            offerDetails?.data?.frameworkOfferLines.length >= 1 ? (
              <div className="space-y-4">
                {filterTable === "all" ||
                (filterTable === "framework" &&
                  offerDetails?.data?.typeOfOrder === "FrameworkOrder") ? (
                  <h3 className="text-lg font-bold font-display">
                    {t("global_as-frame-agree")}
                  </h3>
                ) : null}
                {filterTable === "all" || filterTable === "framework"
                  ? offerDetails?.data?.frameworkOfferLines.map((offer, i) => (
                      <PartFramework
                        index={i + 1}
                        part={offer}
                        key={i}
                        selectionDisabled={
                          !offerDetails.data?.displayAcceptOfferProcessButton
                        }
                      />
                    ))
                  : null}
              </div>
            ) : offerDetails?.data?.typeOfOrder === "FrameworkOrder" &&
              offerDetails?.data?.singleOfferLines.length < 0 ? (
              <div className="flex justify-center">
                <div className="flex flex-col items-center gap-4">
                  <Icons.inquryIllustration />
                  <h2 className="text-2xl font-bold font-display">
                    {t("1-offersoverview-wearecheckingyourinquirydata")}
                  </h2>
                  <p className="text-muted-foreground">
                    {t("1-offersoverview-thankyouforsubmittingyourinquiry")}
                  </p>
                </div>
              </div>
            ) : null}
            {offerDetails?.data?.singleOfferLines &&
            offerDetails?.data?.singleOfferLines.length >= 1 ? (
              <div className="space-y-4">
                {filterTable === "all" ||
                (filterTable === "single" &&
                  offerDetails?.data?.typeOfOrder === "FrameworkOrder") ? (
                  <h3 className="text-lg font-bold font-display">
                    {t("global_as-single-order")}
                  </h3>
                ) : null}
                {/* New version end */}
                {filterTable === "all" || filterTable === "single"
                  ? offerDetails?.data?.singleOfferLines.map((offer, i) => (
                      <PartSingle
                        key={i}
                        part={offer}
                        index={
                          offerDetails.data?.frameworkOfferLines
                            ? offerDetails.data?.frameworkOfferLines.length + 1 + i
                            : i + 1
                        }
                        selectionDisabled={
                          !offerDetails.data?.displayAcceptOfferProcessButton
                        }
                      />
                    ))
                  : null}
              </div>
            ) : offerDetails?.data?.typeOfOrder === "SingleOrder" ? (
              <div className="flex justify-center">
                <div className="flex flex-col items-center gap-4">
                  <Icons.inquryIllustration />
                  <h2 className="text-2xl font-bold font-display">
                    {t("1-offersoverview-wearecheckingyourinquirydata")}
                  </h2>
                  <p className="text-muted-foreground">
                    {t("1-offersoverview-thankyouforsubmittingyourinquiry")}
                  </p>
                </div>
              </div>
            ) : null}
          </CardContent>
        </Card>

        <Card className="border-none shadow-2xl">
          <CardHeader className="text-xl font-bold font-display">
            {t("dashboard-inquiry_details")}
          </CardHeader>
          <CardContent>
            <div className="grid md:grid-cols-3 grid-cols-1 sm:gap-8 gap-4">
              <div className="col-span-2 grid sm:gap-6 gap-3 sm:grid-cols-3 grid-cols-1">
                <div className="break-words space-y-4">
                  <div className="font-bold font-display">{t("contacts_persons")}</div>
                  <div className="font-bold font-display text-sm">
                    <div>{offerDetails?.data?.contactPerson || "-"}</div>
                  </div>
                </div>
                <div className="space-y-4">
                  <div className="font-bold font-display">
                    {t("offers-single-order-sidebar_delivery-address")}
                  </div>
                  <div className="text-sm break-words">
                    {offerDetails?.data?.shippingCompany
                      ? `${offerDetails?.data?.shippingCompany}, `
                      : ""}
                    {offerDetails?.data?.shippingStreet
                      ? `${offerDetails?.data?.shippingStreet}, `
                      : ""}
                    {offerDetails?.data?.shippingPostalCode
                      ? `${offerDetails?.data?.shippingPostalCode}, `
                      : ""}
                    {offerDetails?.data?.shippingCity
                      ? `${offerDetails?.data?.shippingCity}, `
                      : ""}
                    {COUNTRIES[offerDetails?.data?.shippingCountry || "-"]}
                  </div>
                  <div></div>
                </div>
                <div className="space-y-4 border-r pr-4">
                  <div className="font-bold font-display">
                    {t("wizard-ord-details_billing-address")}
                  </div>
                  <div className="text-sm break-words">
                    {offerDetails?.data?.billingCompany
                      ? `${offerDetails?.data?.billingCompany}, `
                      : ""}
                    {offerDetails?.data?.billingStreet
                      ? `${offerDetails?.data?.billingStreet}, `
                      : ""}
                    {offerDetails?.data?.billingPostalCode
                      ? `${offerDetails?.data?.billingPostalCode}, `
                      : ""}
                    {offerDetails?.data?.billingCity
                      ? `${offerDetails?.data?.billingCity}, `
                      : ""}
                    {COUNTRIES[offerDetails?.data?.billingCountry || "-"]}
                  </div>
                </div>
              </div>
              <div>
                <div className="space-y-4">
                  <div className="font-bold font-display">
                    {t("order-details-sidebar_documents")}
                  </div>
                  <ul className="font-bold font-display text-sm space-y-2">
                    {offerDetails?.data?.files && offerDetails?.data?.files.length > 0
                      ? offerDetails.data.files.map((fileObj, index) => {
                          return (
                            <li key={index}>
                              <a
                                target="_blank"
                                className="flex items-center space-x-2 text-primary hover:text-brand"
                                href={fileObj.fileDownloadUrl || fileObj.fileThumbnailUrl}
                                download={true}
                                rel="noreferrer"
                              >
                                <div className="flex">
                                  <div>
                                    <Icons.page className="mr-2 size-4" />
                                  </div>
                                  <div className="break-all">{fileObj.fileName}</div>
                                </div>
                              </a>
                            </li>
                          );
                        })
                      : "-"}
                  </ul>
                </div>
              </div>
            </div>
            {offerDetails?.data && offerDetails.data.comments && (
              <Card className="bg-muted mt-8 rounded-lg">
                <CardContent className="p-6 flex gap-2">
                  <div>
                    <h1 className="text-sm font-bold">
                      {t("text_63c9759e11baf8647f69effd")}:
                    </h1>
                    <p className="text-sm mt-2">{offerDetails.data.comments}</p>
                  </div>
                </CardContent>
              </Card>
            )}
            {offerDetails?.data && offerDetails.data?.orderConditions?.length > 0 && (
              <Card className="bg-muted mt-8">
                <CardContent className="p-6 flex gap-2">
                  <div className="mt-2 mr-4">
                    <Icons.excliMark className="fill-muted-foreground" />
                  </div>
                  <OrderConditions offerDetails={offerDetails?.data} />
                </CardContent>
              </Card>
            )}
          </CardContent>
        </Card>
      </div>
    </div>
  );
};

export default InqueryIdNew;
